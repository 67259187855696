import * as React from "react";

function ChevronDown(props) {
	return (
		<svg viewBox="0 -4.5 24 24" version="1.1" fill="#000000">
			<path
				d="M597.405,1201.63 C596.576,1200.8 595.23,1200.8 594.401,1201.63 L586.016,1210.88 L577.63,1201.63 C576.801,1200.8 575.455,1200.8 574.626,1201.63 C573.797,1202.46 573.797,1203.81 574.626,1204.64 L584.381,1215.4 C584.83,1215.85 585.429,1216.05 586.016,1216.01 C586.603,1216.05 587.201,1215.85 587.65,1215.4 L597.405,1204.64 C598.234,1203.81 598.234,1202.46 597.405,1201.63"
				id="chevron-down"
			></path>
		</svg>
	);
}

export default ChevronDown;
