import { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import Api from "./api/api";
import QrIcon from "../icons/QrIcon";
import functions from "../utilities/functions";

function TableEditPopup() {
	const {
		lists,
		setAlertData,
		setLoading,
		setTable,
		setFlow,
		initCart,
		tempTable,
		setTableEditPopup,
		setPopupVisible,
		ordersDisabled,
		setQrPopupVisible,
		menu,
		shop,
		settings,
	} = useContext(MainContext);
	const [splittedCovers, setSplittedCovers] = useState([]);
	const [covers, setCovers] = useState(0);

	useEffect(() => {
		setSplittedCovers(tempTable.splitted_covers);
		setCovers(tempTable.covers);
	}, [tempTable]);

	useEffect(() => {
		console.log("covers set to", covers);
	}, [covers]);

	useEffect(() => {
		console.log("splittedCovers set to", splittedCovers);
	}, [splittedCovers]);

	const setList = async (listId) => {
		setLoading(true);
		editTableById(tempTable.id, {
			list_id: listId,
		});
	};

	const editTableById = async (id, request) => {
		setLoading(true);
		const res = await Api.postProtected("/tables/edit/" + id + "/", request);
		if (res.success == 0) {
			alert(res.error);
			setLoading(false);
		}
	};

	const editTableByIdConfirm = async (id, request) => {
		setAlertData({
			title: "Sei sicuro?",
			message: "",
			showCancelButton: true,
			callback: async function () {
				editTableById(id, request);
				setTableEditPopup(false);
			},
		});
	};

	const handleSubmit = async () => {
		initCart();
		setFlow(1);
		setTable(tempTable);
		setTableEditPopup(false);
	};

	const incrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			const newData = [...splittedCovers];
			newData[index].qty++;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const decrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			if (splittedCovers[index].qty == 0) return;
			const newData = [...splittedCovers];
			newData[index].qty--;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const getSplittedCovers = (id) => {
		const el = splittedCovers?.find((el) => el.id == id);
		if (el) return el.qty;
		else return 0;
	};

	const getFilteredProducts = (filter) => {
		const res = [];
		for (const course of menu) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (product[filter] == 1) {
						if (tempTable.list_id) {
							const priceList = product.prices.find(
								(p) => p.list_id == tempTable.list_id && p.price
							);
							const assortment = product.assortment.find(
								(el) =>
									el.shop_id == shop.id &&
									el.list_id == tempTable.list_id &&
									el.active == 1
							);
							if (priceList && assortment)
								res.push({ ...product, price: priceList.price });
						} else res.push(product);
					}
				}
			}
		}
		return res;
	};

	const getPin = () => {
		if (
			settings.selfordering.pin_mode == "generated" ||
			settings.selfordering.pin_mode == "fixed"
		)
			return tempTable.pin;
		if (settings.selfordering.pin_mode == "shop") return shop.selfordering_pin;
		if (settings.selfordering.pin_mode == "disabled") return false;
	};

	return (
		<div className="popup md">
			<div className="container covers-table">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setTableEditPopup(false)}
						>
							&times;
						</button>
						<div>
							<span>Modifica</span>
							<div className="name-open-table">
								{tempTable.name}
								<span className="extrasmall"> {tempTable.room.name}</span>
							</div>
						</div>
					</div>
					<div className="buttons-group text-large">
						{getPin() && <div>{"Pin: " + getPin()}</div>}
						<button
							className="btn btn-secondary btn-square btn-icon"
							onClick={() => setQrPopupVisible(true)}
						>
							<QrIcon />
						</button>
					</div>
				</header>
				<div className="content">
					{tempTable.status == 1 && (
						<div className="boxed-section">
							<p className="text-desc">Modifica il listino:</p>
							<div className="lists-container">
								<button
									className={
										"btn" +
										(!tempTable.list_id ? " btn-secondary" : " btn-tertiary")
									}
									onClick={() => setList(null)}
								>
									Base
								</button>
								{lists.map((l, i) => (
									<button
										key={i}
										className={
											"btn" +
											(l.id == tempTable.list_id
												? " btn-secondary"
												: " btn-tertiary")
										}
										onClick={() => setList(l.id)}
									>
										{l.name}
									</button>
								))}
							</div>
						</div>
					)}
					{tempTable.status == 1 &&
						(!tempTable.list_id || tempTable.list.allyoucaneat == 0) && (
							<div className="boxed-section">
								<p className="text-desc">Aggiungi o modifica i coperti:</p>
								<div className="plus-minus">
									<button
										onClick={() =>
											setCovers(
												parseInt(covers) > 0 ? parseInt(covers) - 1 : 0
											)
										}
									>
										&#8722;
									</button>
									<input
										type="number"
										value={covers}
										onChange={(event) =>
											setCovers(parseInt(event.target.value))
										}
									/>
									<button onClick={() => setCovers(parseInt(covers) + 1)}>
										&#43;
									</button>
								</div>
							</div>
						)}
					{tempTable.status == 1 &&
						tempTable.list_id &&
						tempTable.list.allyoucaneat == 1 && (
							<div className="boxed-section">
								<div className="group vertical">
									<p className="text-desc">Aggiungi o modifica i menu:</p>
									{getFilteredProducts("is_selfordering_menu").map(
										(product, i) => (
											<div key={i} className="product-line">
												<div>{product.name}</div>
												<div className="plus_minus_container">
													{functions.formatter.format(product.price)}
													{tempTable.status == 1 && (
														<button
															className="btn btn-primary btn-square"
															onClick={() =>
																decrementSplittedCovers(product.id)
															}
														>
															-
														</button>
													)}
													<div>{getSplittedCovers(product.id)}</div>
													{tempTable.status == 1 && (
														<button
															className="btn btn-primary btn-square"
															onClick={() =>
																incrementSplittedCovers(product.id)
															}
														>
															+
														</button>
													)}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						)}
					{tempTable.status == 2 && (
						<div className="boxed-section">
							<p className="text-desc">Tavolo in attesa di conto</p>
						</div>
					)}
				</div>
				<div className="group vertical">
					{tempTable.status == 1 && (
						<button
							className="btn btn-secondary btn-block"
							onClick={() =>
								editTableByIdConfirm(tempTable.id, {
									covers: covers,
									splitted_covers: splittedCovers,
								})
							}
						>
							Salva modifiche
						</button>
					)}
					{!ordersDisabled && tempTable.status == 1 && (
						<button className="btn btn-primary btn-block" onClick={handleSubmit}>
							Nuova comanda
						</button>
					)}
					<button
						onClick={() => setPopupVisible("history")}
						className="btn btn-primary btn-block btn-icon"
					>
						<div className="badge">{tempTable.orders_count}</div>
						Visualizza ordini
					</button>
					<div className="status-button">
						{tempTable.status == 1 && (
							<button
								className="btn btn-primary btn-block"
								onClick={() => editTableByIdConfirm(tempTable.id, { status: 2 })}
							>
								Richiedi conto
							</button>
						)}
						{tempTable.status == 2 && (
							<button
								className="btn btn-primary btn-block"
								onClick={() => editTableById(tempTable.id, { status: 1 })}
							>
								Annulla richiesta di conto
							</button>
						)}
						{settings.orders.tables_open_by_filled != 1 && (
							<button
								className="btn btn-primary btn-block"
								onClick={() => editTableByIdConfirm(tempTable.id, { status: 0 })}
							>
								Chiudi Tavolo
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TableEditPopup;
