import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import HamburgerIcon from "../icons/HamburgerIcon";
import SearchIcon from "../icons/SearchIcon";

function Tables() {
	const {
		shop,
		setShop,
		settings,
		setPopupVisible,
		tables,
		rooms,
		table,
		setTempTable,
		setTableEditPopup,
		ordersDisabled,
		shops,
		setMenuVisible,
	} = useContext(MainContext);
	const [room, setRoom] = useState(false);
	const [search, setSearch] = useState("");

	useEffect(() => {
		const filtered = rooms.filter((r) => r.shop_id == shop.id);
		if (filtered.length > 0) setRoom(filtered[0]);
	}, []);

	useEffect(() => {
		if (shop) {
			const filtered = rooms.filter((r) => r.shop_id == shop.id);
			if (filtered.length > 0) setRoom(filtered[0]);
			else setRoom(false);
		} else setRoom(false);
	}, [shop]);

	useEffect(() => {
		console.log("room set to", room);
	}, [room]);

	const handleClick = async (table) => {
		console.log("handleClick");
		console.log("covers", table.covers);
		setTempTable(table);
		if (table.status == 0) {
			setPopupVisible("covers");
		} else {
			setTableEditPopup(true);
		}
	};

	const getClassName = (status) => {
		if (status == 0) return "";
		if (status == 1) return "bg-success";
		if (status == 2) return "bg-warning";
	};

	const getPin = (table) => {
		if (
			settings.selfordering.pin_mode == "generated" ||
			settings.selfordering.pin_mode == "fixed"
		)
			return table ? table.pin : null;
		if (settings.selfordering.pin_mode == "shop") return shop.selfordering_pin;
		if (settings.selfordering.pin_mode == "disabled") return false;
	};

	return (
		<div className="popup full">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							onClick={() => setMenuVisible(true)}
							className="btn btn-tertiary btn-square"
						>
							<HamburgerIcon />
						</button>
						{table && (
							<button
								className="btn btn-tertiary btn-square"
								onClick={() => setPopupVisible(false)}
							>
								&times;
							</button>
						)}
						{ordersDisabled ? (
							<div className="horizzontal-menu">
								<div className="scroll-content">
									{shops &&
										shops.map((s, i) => (
											<button
												key={i}
												className={
													parseInt(s.id) == parseInt(shop.id)
														? "btn btn-secondary"
														: "btn btn-tertiary"
												}
												onClick={() => setShop(s)}
											>
												{s.name}
											</button>
										))}
								</div>
							</div>
						) : (
							<div>Tavoli</div>
						)}
					</div>
					<div className="header-src">
						<input
							type="text"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<SearchIcon />
					</div>
				</header>
				<div className="horizzontal-menu">
					<div className="scroll-content">
						{rooms &&
							rooms
								.filter((r) => r.shop_id == shop.id)
								.map((r, i) => (
									<button
										key={i}
										className={
											parseInt(r.id) == parseInt(room.id)
												? "btn btn-primary"
												: "btn btn-tertiary"
										}
										onClick={() => setRoom(r)}
									>
										{r.name}
									</button>
								))}
					</div>
				</div>
				<div className="content">
					<div className="tables-container">
						{room && tables ? (
							tables
								.filter((t) => {
									if (search) {
										return (
											t.room_id == room.id &&
											t.name.toLowerCase().search(search.toLowerCase()) > -1
										);
									} else return t.room_id == room.id;
								})
								.map((t, i) => (
									<button
										onClick={() => handleClick(t)}
										key={i}
										className={getClassName(t.status)}
									>
										<div className="header">
											<div>
												<b>{t.name}</b>
											</div>
											{t.status == 1 && (
												<div className="duration">{t.duration}</div>
											)}
										</div>
										<div className="info extrasmall">
											<div>
												<div>
													Listino: {t.list_id ? t.list.name : "Base"}
												</div>
												{t.user && <div>Cameriere: {t.user.name}</div>}
												{t.status != 0 && getPin(t) && (
													<div className="text-bold">
														{"Pin: " + getPin(t)}
													</div>
												)}
											</div>
											<div className="text-right">
												<div className="extrasmall">
													Ordini: {t.orders_count}
												</div>
												{settings.waiter_app.enable_flow == 1 && (
													<div className="extrasmall">
														Portata: {t.flow}
													</div>
												)}
												<div className="extrasmall">
													Coperti: {t.covers}
												</div>
											</div>
										</div>
									</button>
								))
						) : (
							<div className="full-centered">
								<div>
									Nessun tavolo trovato
									<br />
									<button onClick={() => setShop(false)}>
										Torna alla scelta del negozio
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Tables;
