import React, { useEffect, useState } from "react";
import Alert from "./components/Alert";
import Api from "./components/api/api";
import CategoriesSelect from "./components/CategoriesSelect";
import FlowPopup from "./components/FlowPopup";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HistoryPopup from "./components/HistoryPopup";
import Loading from "./components/Loading";
import Menu from "./components/Menu";
import Product from "./components/Product";
import Products from "./components/Products";
import Shops from "./components/Shops";
import Tables from "./components/Tables";
import Toast from "./components/Toast";
import VariantsPopup from "./components/VariantsPopup";
import Login from "./components/Login";
import OrderNotes from "./components/OrderNotes";
import ProductNotes from "./components/ProductNotes";
import Covers from "./components/Covers";
import moment from "moment/moment";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import "./css/App.css";
import "./css/Popup.css";
import "./css/Menu.css";
import "./css/Login.css";
import "./css/MenuCategories.css";
import Socket from "./Socket";
import Cart from "./components/Cart";
import TableEditPopup from "./components/TableEditPopup";
import QrPopup from "./components/QrPopup";

export const MainContext = React.createContext();

let socketUrl = getSocketUrl();
console.log("socketUrl", socketUrl);
const socket = io(socketUrl);

function App() {
	const queryParams = new URLSearchParams(window.location.search);
	const [settings, setSettings] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alertData, setAlertData] = useState(false);
	const [popupData, setPopupData] = useState(false);
	const [shop, setShop] = useState(false);
	const [table, setTable] = useState(false);
	const [list, setList] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [cart, setCart] = useState(false);
	const [productSheet, setProductSheet] = useState(false);
	const [variantsPopup, setVariantsPopup] = useState(false);
	const [toast, setToast] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const [user, setUser] = useState(false);
	const [config, setConfig] = useState(
		localStorage.getItem("waiter-config")
			? JSON.parse(localStorage.getItem("waiter-config"))
			: false
	);
	const [tables, setTables] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [menu, setMenu] = useState(false);
	const [courses, setCourses] = useState(false);
	const [category, setCategory] = useState(false);
	const [flow, setFlow] = useState(1);
	const [cartVisible, setCartVisible] = useState(false);
	const [animations, setAnimations] = useState([]);
	const [tempTable, setTempTable] = useState([]);
	const [lists, setLists] = useState([]);
	const [tableEditPopup, setTableEditPopup] = useState(false);
	const [ordersDisabled, setOrdersDisabled] = useState(false);
	const [shops, setShops] = useState(false);
	const [qrPopupVisible, setQrPopupVisible] = useState(false);
	const [stock, setStock] = useState([]);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (shops && localStorage.getItem("waiter-shop-id")) {
			setShop(shops.find((s) => s.id == localStorage.getItem("waiter-shop-id")));
		}
	}, [shops]);

	useEffect(() => {
		if (shop) {
			loadLists();
			localStorage.setItem("waiter-shop-id", shop.id);
		}
	}, [shop]);

	useEffect(() => {
		console.log("table set to", table);
		if (table) setList(table.list_id ? { id: table.list_id } : false);
	}, [table]);

	useEffect(() => {
		console.log("list set to", list);
	}, [list]);

	useEffect(() => {
		console.log("cart set to", cart);
	}, [cart]);

	useEffect(() => {
		console.log("tempTable set to", tempTable);
	}, [tempTable]);

	useEffect(() => {
		if (menu) filterMenu();
	}, [menu, list, shop, settings, stock]);

	useEffect(() => {
		if (table) setTable(tables.find((t) => t.id == table.id));
		if (tempTable) setTempTable(tables.find((t) => t.id == tempTable.id));
	}, [tables]);

	useEffect(() => {
		if (user) loadData();
	}, [user]);

	useEffect(() => {
		if (settings) {
			setOrdersDisabled(
				queryParams.get("disable_orders") == 1 || settings.mandant.disable_orders == 1
			);
		}
	}, [settings]);

	useEffect(() => {
		console.log(
			"ordersDisabled set to",
			ordersDisabled,
			queryParams.get("disable_orders"),
			settings?.mandant?.disable_orders
		);
	}, [ordersDisabled]);

	async function init() {
		console.log("init");

		Api.setBaseUrl(config);
		await load_settings();
		await restoreUser();

		setLoaded(true);
	}

	async function loadData() {
		console.log("loadData");
		setLoaded(false);
		await loadMenu();
		await loadShops();
		await loadRooms();
		await loadTables();
		await loadStock();
		setLoaded(true);
	}

	async function restoreUser() {
		if (queryParams.get("user_id")) {
			const id = queryParams.get("user_id");
			const refresh = await Api.autoLogin(id);
			if (refresh.success == 0) alert(refresh.error);
			else {
				localStorage.setItem("waiter-user", refresh.token);
				const decoded = jwtDecode(refresh.token);
				const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
				if (response.success == 0) alert(response.error);
				else setUser(response.data);
			}
		} else {
			const token = localStorage.getItem("waiter-user");
			if (token) {
				const refresh = await Api.refreshToken(token);
				if (refresh.success == 0) alert(refresh.error);
				else {
					localStorage.setItem("waiter-user", refresh.token);
					const decoded = jwtDecode(refresh.token);
					const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
					if (response.success == 0) alert(response.error);
					else setUser(response.data);
				}
			}
		}
	}

	async function initCart() {
		console.log("initCart");
		setCart({ notes: null, products: [] });
	}

	async function load_settings() {
		console.log("load_settings");
		const response = await Api.post("/settings2/get/", {
			section: ["global", "orders", "waiter_app", "menu_api", "selfordering"],
		});
		setSettings(response.data);

		document.title = "APP Cameriere | " + response.data.global.name;
	}

	const loadMenu = async () => {
		console.log("loadMenu");
		const response = await Api.post("/menu/courses/");
		setMenu(response.rows);
	};

	const loadLists = async () => {
		console.log("loadLists");
		setLoading(true);
		const response = await Api.postProtected("/waiters_app/lists/", {
			shop_id: shop.id,
		});
		setLoading(false);
		setLists(response.rows);
	};

	const loadShops = async () => {
		console.log("loadShops");
		const response = await Api.post("/shops/list/", {
			orderby: "position",
			fields: ["id", "name", "selfordering_pin"],
			depth: 0,
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
			],
		});
		setShops(response.rows);
	};

	const loadRooms = async () => {
		console.log("loadRooms");
		const response = await Api.postProtected("/waiters_app/rooms/", {
			fields: ["id", "name"],
		});
		setRooms(response.rows);
	};

	const loadTables = async () => {
		console.log("loadTables");
		const response = await Api.postProtected("/waiters_app/tables/");
		setTables(response.rows);
		setLoading(false);
	};

	const loadStock = async () => {
		console.log("loadStock");
		const response = await Api.postProtected("/products_stock/list_for_clients/");
		setLoading(false);
		if (response.success == 1) {
			setStock(response.rows);
		} else alert(response.error);
	};

	const handleAddProduct = (product, variants = false, ingredients = false, flow = false) => {
		console.log("handleAddProduct");
		if (product.stock === false || getCartQty(product.id) < product.stock) {
			if (
				(!variants && product.variants_categories.length > 0) ||
				(!ingredients && hasRemovableIngredients(product))
			) {
				setVariantsPopup({
					product: { ...product },
					index: false,
				});
			} else if (!flow && settings.waiter_app.enable_flow == 1) {
				setPopupData({
					product: product,
					variants: variants,
					callback: handleAddProduct,
				});
				setPopupVisible("flow");
			} else {
				addProduct(product, variants, ingredients, flow);
				setVariantsPopup(false);
			}
		}
	};

	const hasRemovableIngredients = (product) => {
		const removableIngredients = product.ingredients.filter((i) => i.removable == 1);
		if (removableIngredients.length > 0) return true;
		else return false;
	};

	const getCartQty = (productId) => {
		let count = 0;
		for (const p of cart.products) {
			if (productId == p.id) count += p.qty;
		}
		return count;
	};

	const addProduct = (product, selectedVariants, selectedIngredients, requestedFlow) => {
		console.log("addProduct");
		product.qty = 1;
		product.flow = requestedFlow ? requestedFlow : flow;
		product.selectedVariants = selectedVariants;
		product.selectedIngredients = selectedIngredients;
		product.notes = "";
		let tempCart = { ...cart };
		const pIndex = checkIfProductExist(tempCart.products, product, product.flow);
		console.log("pIndex", pIndex);
		if (pIndex === false) {
			tempCart.products.push({ ...product });
		} else {
			tempCart.products[pIndex].qty++;
		}
		setCart(tempCart);
		setAnimations([...animations, product.id]);
	};

	const saveProduct = (productIndex, product) => {
		console.log("saveProduct");
		let tempCart = { ...cart };
		tempCart.products[productIndex] = product;
		setCart(tempCart);
		setVariantsPopup(false);
	};

	const editQty = (productIndex, type) => {
		console.log("editQty");
		let tempCart = { ...cart };
		setAnimations([...animations, tempCart.products[productIndex].id]);
		if (type == "plus") {
			const stock = getLiveStock(tempCart.products[productIndex].id);
			if (stock === false || getCartQty(tempCart.products[productIndex].id) < stock)
				tempCart.products[productIndex].qty++;
		} else {
			tempCart.products[productIndex].qty--;
			if (tempCart.products[productIndex].qty <= 0) tempCart.products.splice(productIndex, 1);
		}
		setCart(tempCart);
	};

	const getLiveStock = (productId) => {
		for (const c of courses) {
			for (const cat of c.categories) {
				for (const p of cat.products) {
					if (p.id == productId) return p.stock;
				}
			}
		}
		return false;
	};

	const editFlow = (productIndex, type) => {
		console.log("editFlow");
		let tempCart = { ...cart };
		if (type == "plus") tempCart.products[productIndex].flow++;
		else {
			tempCart.products[productIndex].flow--;
		}
		setCart(tempCart);
	};

	const emptyCart = () => {
		console.log("emptyCart");
		let tempCart = { ...cart };
		tempCart.products = [];
		tempCart.notes = null;
		setCart(tempCart);
	};

	const checkout = async () => {
		console.log("checkout");
		try {
			setLoading(true);

			const stockCheck = await Api.postProtected("/products_stock/check/", {
				shop_id: shop.id,
				products: cart.products,
			});
			if (stockCheck.success == 0) {
				setAlertData({
					title: "Errore",
					message: stockCheck.error,
				});
				setLoading(false);
				return;
			}

			let ordersSessionId = table.cart_id;
			console.log(ordersSessionId);

			let tempCart = { ...cart };

			let request = {
				delivery_type: "shop",
				delivery_date: moment().format("YYYY-MM-DD HH:mm:ss"),
				status: table.room.waiter_order_status || "confirmed",
				total: 0,
				origin: "waiter",
				shop_id: shop.id,
				list_id: list ? list.id : null,
				room_id: table.room_id,
				table_id: table.id,
				user_id: user.id,
				self_cart_id: ordersSessionId,
				notes: tempCart.notes,
				products: [],
			};

			for (const p of tempCart.products) {
				let product = {
					product_id: p.id,
					name: p.name,
					qty: p.qty,
					price: parseFloat(p.price),
					flow: p.flow,
					notes: p.notes,
					category_id: p.category_id,
					variants: [],
					products: [],
				};
				if (p.selectedVariants !== false) {
					for (const vc of p.selectedVariants) {
						for (const v of vc.variants) {
							if (v.qty > 0) {
								let variant = {
									variant_category_id: vc.id,
									variant_id: v.id,
									name: v.name,
									qty: v.qty,
									price: v.price,
								};
								product.variants.push(variant);
								product.price += parseFloat(v.price) * parseInt(v.qty);
							}
						}
					}
				}
				if (p.selectedIngredients !== false) {
					for (const i of p.selectedIngredients) {
						if (!i.selected) product.notes += "<br>No " + i.name;
					}
				}
				request.products.push(product);
				request.total += parseFloat(product.price) * parseInt(p.qty);
			}
			const response = await Api.postProtected("/orders/add/", request);
			if (response.success == 0) {
				alert(response.error);
				setLoading(false);
			} else {
				setLoading(false);
				emptyCart();
				setFlow(1);
				setCartVisible(false);
			}
		} catch (err) {
			setLoading(false);
			alert("Error: " + err);
		}
	};

	const editTable = async (request) => {
		console.log("editTable");
		setLoading(true);
		await Api.postProtected("/tables/edit/" + table.id + "/", request);
	};

	const lockTable = async () => {
		console.log("lockTable");
		setAlertData({
			title: "Sei sicuro?",
			message: "",
			showCancelButton: true,
			callback: async function () {
				setPopupVisible(false);
				editTable({ status: 2 });
				setTable(false);
			},
		});
	};

	const findIndex = (arr, srcParam, srcValue) => {
		let i = 0;
		for (const el of arr) {
			if (el[srcParam] == srcValue) return i;
			i++;
		}
		return false;
	};

	const checkIfProductExist = (products, newProduct, flow) => {
		let i = 0;
		for (const product of products) {
			let exist = false;

			if (
				product.id == newProduct.id &&
				product.flow == flow &&
				product.notes == newProduct.notes &&
				JSON.stringify(product.selectedVariants) ===
					JSON.stringify(newProduct.selectedVariants) &&
				JSON.stringify(product.selectedIngredients) ===
					JSON.stringify(newProduct.selectedIngredients)
			)
				exist = true;

			console.log(product.id, newProduct.id, product.flow, flow, exist);

			if (exist) return i;

			i++;
		}
		return false;
	};

	function filterMenu() {
		console.log("filterMenu", shop, list);
		let res = [];
		for (const course of menu) {
			const cr = structuredClone(course);
			cr.categories = [];
			for (const category of course.categories) {
				if (!category.hasOwnProperty("active_pos") || category.active_pos == 1) {
					const ct = structuredClone(category);
					if (shop && list) {
						ct.products = [];
						for (const product of category.products) {
							let p = filterVariants(product);
							p.stock = getProductStock(product.id);
							const priceList = product.prices.filter((el) => {
								return el.list_id == list.id && el.price;
							});
							const assortment = product.assortment.filter((el) => {
								return (
									el.shop_id == shop.id && el.list_id == list.id && el.active == 1
								);
							});
							if (
								priceList.length > 0 &&
								assortment.length > 0 &&
								check_ingredients_assortment(product)
							) {
								p.price = priceList[0].price;
								ct.products.push(p);
							}
						}
						if (ct.products.length > 0) cr.categories.push(ct);
					} else if (shop) {
						ct.products = [];
						for (const product of category.products) {
							let p = filterVariants(product);
							p.stock = getProductStock(product.id);
							if (check_ingredients_assortment(product)) {
								ct.products.push(p);
							}
						}
						if (ct.products.length > 0) cr.categories.push(ct);
					} else {
						if (category.products.length > 0) cr.categories.push(category);
					}
				}
			}
			if (cr.categories.length > 0) res.push(cr);
		}
		setCourses(res);
		if (!category && res.length > 0 && res[0].categories.length > 0)
			setCategory(res[0].categories[0]);
		if (category) {
			const i = res.findIndex((vc) => {
				const cat = vc.categories.filter((c) => {
					return c.id == category.id;
				});
				return cat.length > 0;
			});

			if (i > -1) {
				const n = res[i].categories.findIndex((c) => c.id == category.id);
				if (n > -1) setCategory(res[i].categories[n]);
				else setCategory(res[0].categories[0]);
			} else if (res.length == 0) setCategory({ categories: [] });
			else setCategory(res[0].categories[0]);
		}
	}

	const filterVariants = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.variants_categories = [];
		for (const variants_category of product.variants_categories) {
			let vc = JSON.parse(JSON.stringify(variants_category));
			vc.variants = [];
			vc.variants2 = [];
			vc.products = [];
			for (const variant of variants_category.variants) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants.push(v);
					}
				} else {
					vc.variants.push(v);
				}
			}
			for (const variant of variants_category.variants2) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants2.push(v);
					}
				} else {
					vc.variants2.push(v);
				}
			}
			for (const variant of variants_category.products) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (
						priceList.length > 0 &&
						(assortment.length > 0) & check_ingredients_assortment(variant)
					) {
						v.price = priceList[0].price;
						vc.products.push(v);
					}
				} else if (shop) {
					if (check_ingredients_assortment(variant)) {
						vc.products.push(v);
					}
				} else {
					vc.products.push(v);
				}
			}
			if (vc.variants.length > 0 || vc.variants2.length > 0 || vc.products.length > 0)
				p.variants_categories.push(vc);
		}
		return p;
	};

	const check_ingredients_assortment = (product) => {
		if (!settings.menu_api) return true;
		if (!settings.menu_api.enable_ingredients_assortment) return true;
		if (settings.menu_api.enable_ingredients_assortment != 1) return true;
		if (!product.ingredients) return true;
		if (product.ingredients.length == 0) return true;
		for (const ingredient of product.ingredients) {
			const assortment = ingredient.assortment.filter((el) => {
				return el.shop_id == shop.id && el.active == 1;
			});
			if (assortment.length == 0) return false;
		}
		return true;
	};

	const getProductStock = (productId) => {
		const s = stock.find((e) => e.product_id == productId && e.shop_id == shop.id);
		if (s) return s.qty;
		return false;
	};

	function handleSocketEvent(type, data) {
		console.log("handleSocketEvent", type);
		if (type == "settings") load_settings();
		if (type == "rooms") loadRooms();
		if (type == "tables") loadTables();
		if (type == "menu") loadMenu();
		if (type == "products_stock") loadStock();
		if (type == "orders") {
			//loadOrders();
			loadTables();
		}
	}

	return (
		<>
			{settings && shop && (
				<Socket
					socket={socket}
					mandantId={settings.mandant.id}
					onMessage={handleSocketEvent}
					shop={shop}
					settings={settings}
					config={config}
				/>
			)}
			{loaded ? (
				<MainContext.Provider
					value={{
						settings,
						shop,
						setShop,
						list,
						table,
						setTable,
						popupVisible,
						setPopupVisible,
						popupData,
						setPopupData,
						category,
						setCategory,
						courses,
						addProduct,
						saveProduct,
						emptyCart,
						editQty,
						editFlow,
						checkout,
						setLoading,
						alertData,
						setAlertData,
						popupData,
						setPopupData,
						productSheet,
						setProductSheet,
						handleAddProduct,
						variantsPopup,
						setVariantsPopup,
						toast,
						setToast,
						menuVisible,
						setMenuVisible,
						user,
						setUser,
						menu,
						findIndex,
						cart,
						setCart,
						flow,
						setFlow,
						editTable,
						tables,
						rooms,
						setCartVisible,
						lockTable,
						animations,
						setAnimations,
						initCart,
						tempTable,
						setTempTable,
						lists,
						setTableEditPopup,
						ordersDisabled,
						shops,
						setQrPopupVisible,
					}}
				>
					{!user ? (
						<Login />
					) : (
						<>
							{shop && table && (
								<div className="main">
									<Header />
									<div className="content">
										{settings.waiter_app.categories_menu_type == 2 && (
											<CategoriesSelect />
										)}
										{(!settings.waiter_app.categories_menu_type ||
											settings.waiter_app.categories_menu_type == 1) &&
											category && (
												<div className="category-title">
													{category.name.toUpperCase()}
												</div>
											)}
										<Products />
									</div>
									<Footer />
								</div>
							)}
							{shop && !table && <Tables />}
							{tableEditPopup && (
								<>
									<div
										className="overlay"
										onClick={() => setTableEditPopup(false)}
									></div>
									<TableEditPopup />
								</>
							)}
							{cartVisible && <Cart />}
							{menuVisible && <Menu />}
							{productSheet && (
								<>
									<div
										className="overlay"
										onClick={() => setTableEditPopup(false)}
									></div>
									<Product />
								</>
							)}
							{variantsPopup && <VariantsPopup />}
							{popupVisible && (
								<>
									<div
										className="overlay"
										onClick={() => setPopupVisible(false)}
									></div>
									{popupVisible == "covers" && <Covers />}
									{popupVisible == "flow" && <FlowPopup />}
									{popupVisible == "history" && <HistoryPopup />}
									{popupVisible == "orderNotes" && <OrderNotes />}
									{popupVisible == "productNotes" && <ProductNotes />}
								</>
							)}
							{qrPopupVisible && (
								<>
									<div
										className="overlay"
										onClick={() => setQrPopupVisible(false)}
									></div>
									<QrPopup />
								</>
							)}
							{!shop && <Shops />}
						</>
					)}
					{alertData && <Alert />}
					{loading && <Loading />}
					<Toast />
					{settings && settings.mandant.expired && (
						<div className="expire">
							<div className="content">
								<h3>ATTENZIONE</h3>
								Licenza scaduta da {Math.abs(settings.mandant.diff_days)}{" "}
								{parseInt(Math.abs(settings.mandant.diff_days)) == 1
									? "giorno"
									: "giorni"}{" "}
								giorni
								<br /> Per il rinnovo dei servizi contatta il commerciale di
								riferimento
							</div>
						</div>
					)}
				</MainContext.Provider>
			) : (
				<Loading />
			)}
		</>
	);
}

function getSocketUrl() {
	const hostname = window.location.hostname;
	let socketUrl;
	if (hostname.includes("localhost")) {
		let localhost_mode = false;
		if (localStorage.getItem("waiter-config")) {
			const config = JSON.parse(localStorage.getItem("waiter-config"));
			if (config.mode == "local") localhost_mode = true;
		}
		if (localhost_mode) socketUrl = "ws://localhost:3000";
		else {
			socketUrl = "https://ws.yellgo.cloud:3200";
			//socketUrl = "https://ws.yellgo.cloud:3000";
		}
	} else if (hostname.includes(".demo.")) socketUrl = "https://ws.yellgo.cloud:3100";
	else if (hostname.includes(".devaws.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
	else if (hostname.includes(".dev.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
	else socketUrl = "https://ws.yellgo.cloud";
	return socketUrl;
}

export default App;
